export default async function fetchJson<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit,
  retry = false,
): Promise<any> {
  let req = input;
  if (typeof req === "string") {
    if (
      process.env.NODE_ENV === "development" &&
      process.env.NEXT_PUBLIC_PROXY !== "no"
    ) {
      req = `/api/proxy/backend/${req}`;
    } else {
      req = `/backend/${req}`;
    }
  }
  const options = {
    ...init,
    headers: {
      ...init?.headers,
      "Content-Type": "application/json",
    } as any,
  };
  const response = await fetch(req, options);

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line

  const data = await response.json();

  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    if (data.data) {
      return data.data;
    }
    return data;
  }
  if (
    "result" in data &&
    "error" in data.result &&
    data.code !== 404 &&
    data.code !== 401
  ) {
    alert(data.result.error);
  }

  if (response.status === 418) {
    throw new Error("access-code");
  }

  if (response.status === 402) {
    throw new Error("payment-required");
  }

  if (response.status === 401) {
    if (!window.location.pathname.includes("/auth")) {
      window.location.href = "/auth";
    }
    return;
  }

  if (response.status === 403) {
    window.history.replaceState({}, "", "/unauthorized");
  }
  if (response.status >= 500) {
    return null;
  }

  console.log(
    "ERRRRR",
    new FetchError({
      message: response.statusText,
      response,
      data,
    }),
  );
  return data;
}

export class FetchError extends Error {
  response: Response;
  data: {
    message: string;
  };
  constructor({
    message,
    response,
    data,
  }: {
    message: string;
    response: Response;
    data: {
      message: string;
    };
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.response = response;
    this.data = data ?? { message: message };
  }
}
